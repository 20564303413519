import { useRouter } from 'next/router';
import Button from '~/components/Button';
import Container from '~/components/Container';
import Colors from '~/constants/colors';
import { getTheme } from '~/utils/theme';
import SearchFilter from '../SearchFilter';
import Input from '~/components/Input';
import { useState } from 'react';
import Icon from '~/components/Icon';
import SearchIcon from '~/assets/svgs/search.svg';
import ArrowLeftIcon from '~/assets/svgs/arrow-left.svg';
import dynamic from 'next/dynamic';
import { renderSectionByKey } from '~/utils/pageTwo';
import { SectionsKey } from '~/constants/enum';

const SimilarProducts: any = renderSectionByKey(SectionsKey.SimilarProduct);
const Error = () => {
    const router = useRouter();
    const [searchValue, setSearchValue] = useState('');

    const handleChangeSearchValue = (value: string) => {
        setSearchValue(value);
    };

    const handleSearchProduct = (value: string) => {
        if (!value) return;
        router.push(`/search?keyword=${value}`);
    };

    const handleKeyDownSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearchProduct(searchValue);
        }
    };

    return (
        <div className="w-full flex flex-col justify-center pt-24 min-h-screen">
            <div className="mb-8">
                <Container>
                    <div className="flex flex-col justify-center items-center gap-4">
                        <div>
                            <div className="font-semibold text-4xl text-center mb-2">Error 404</div>
                            <div className="flex justify-center"></div>
                            <h2 className="text-center ">
                                The page you are looking for might have been removed had its name changed or is
                                temporarily unavailable.
                            </h2>
                            <p className="text-center">Please back to homepage or check our offer</p>
                        </div>

                        <div className="w-full max-w-[500px] ">
                            <Input
                                placeholder="Search your sneaker..."
                                value={searchValue}
                                className="w-full"
                                onChange={(e) => handleChangeSearchValue(e.target.value)}
                                onKeyDown={handleKeyDownSearch}
                                onClearText={() => handleChangeSearchValue('')}
                                suffix={
                                    <SearchIcon
                                        className="hover:cursor-pointer"
                                        width={20}
                                        heihgt={20}
                                        onClick={() => handleSearchProduct(searchValue)}
                                    />
                                }
                            />
                            <span
                                className="inline-flex items-center gap-2 pt-4 hover:cursor-pointer hover:underline"
                                onClick={() => router.push('/')}
                            >
                                <ArrowLeftIcon className="animation" width={20} height={20} />{' '}
                                <span>Back to homepage</span>
                            </span>
                        </div>
                    </div>
                </Container>
            </div>
            <div style={{ backgroundColor: Colors.PRIMARY_LIGHT }} className="h-full">
                <SimilarProducts />
            </div>
        </div>
    );
};

export default Error;
